module Main exposing (..)

import Browser
import Browser.Events exposing (onAnimationFrameDelta, onResize)
import Html as H
import Html.Attributes as HA
import Init exposing (init)
import Message exposing (Msg)
import Model exposing (Model)
import Update exposing (update)
import View
import View.Calendar as Calendar
import View.Chart
import View.Toggle as Toggle



---- SUBSCRIPTIONS ----


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.batch
        [ onAnimationFrameDelta Message.Tick
        , onResize Message.OnWindowResize
        ]



---- PROGRAM ----


main : Program { windowWidth : Int, windowHeight : Int, data : Model.LocalStorageData } Model Msg
main =
    Browser.element
        { view =
            \model ->
                View.view model
        , init = init
        , update = update
        , subscriptions = subscriptions
        }
