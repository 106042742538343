import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

let data;
try {
  const json = localStorage.getItem("data");
  data = JSON.parse(json);
  if (!data) {
    throw new Error('!!!');
  }
} catch (e) {
  data = {
    sex: 'Male',
    weight: '',
    height: '',
    age: '',
    dailyCalories: '',
    activityLevel: 'Sedetary',
    weightMode: 'Kg',
    heightMode: 'Cm'
  }
}

const app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: { windowWidth: window.innerWidth, windowHeight: window.innerHeight, data },
});

console.log(app)

// app.ports.triggerVibrate.subscribe((vibrationTime) => {
//   if (window.navigator.vibrate) {
//     window.navigator.vibrate(vibrationTime);
//   }
// });


app.ports.saveToLocalStorage.subscribe((data) => {
  try {
    localStorage.setItem('data', JSON.stringify(data))
  } catch (e) {
    
  }
});



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
